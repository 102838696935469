import React,{useState, useEffect} from 'react'

//backend
import { auth, db } from '../firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import {  setDoc, doc } from "firebase/firestore";

//router
import { Link, useNavigate } from "react-router-dom";

import { motion } from "framer-motion"

import { TypeAnimation } from 'react-type-animation';

import OtpInput from 'react-otp-input';

import Wave from 'react-wavify'


export default function LoginScreen() {
  //init navigator
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  const [otp,setOtp] = useState("")
  const [phoneNumber,setPhoneNumber] = useState("")
  const [screenState,setScreenState] = useState("pn")
  const [loadingState, setLoadingState] = useState(true)

  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
      if (user){
      navigate("/dashboard");
      }
      if (loading) {
        setLoadingState(true)
      }else{
        setLoadingState(false)
      }
    }, [user, loading]);

  useEffect(() => {
    reCaptcha();
  },[])


  function reCaptcha(){
    console.log("inrecap")
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      }
    }, auth);
  }

  function otpSubmit(){
    window.confirmationResult.confirm(otp).then((result) => {
      // User signed in successfully.
      createUser(result?.user?.phoneNumber,result?.user?.uid)
      // ...
    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      alert("invalid OTP")
      // ...
    });
  }

  const handleAnimationComplete = () => {
    setShowContent(true);
  };

  async function createUser(pn,uid){
    await setDoc(doc(db, "Users",pn), {
        uid: uid,
        phoneNumber: pn,
    }, { merge: true }).then((e) => {console.log("User Created")})
  }


  function signInWPhoneNumber(){
    console.log("in")
    if(phoneNumber != "" && phoneNumber.length == 10){
        setScreenState("otp")
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, `+91${phoneNumber}`, appVerifier)
            .then((confirmationResult) => {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult;
              // ...
            }).catch((error) => {
              // Error; SMS not sent
              console.log(error)
              // ...
            });
    }else{
      alert("Invalid Phone Number")
    }
  } 

  return (
    <div>
       {loadingState && (
            <div style={{height: "100vh", width: "100vw", backgroundColor: 'black', position: 'fixed', zIndex: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {/* <h1>Loading</h1> */}
            </div>
        )}
      {screenState == "pn" && (
        <div style={{height: "100vh", width: "100vw", backgroundColor: 'black', display: 'flex', flexDirection: 'column'}}>
           <h1 style={{color: 'white', fontSize: '2.4rem', marginLeft: 50, marginTop: '5vh'}}>Enter your<br/><span style={{fontSize: "3.0rem", color: 'lemonchiffon'}}>Phone <br/> <span style={{fontSize: "4rem", color: 'lemonchiffon'}}>Number</span> </span><br/> to receive your<br/></h1>
           <TypeAnimation
              sequence={[
                'OTP',
                1000, 
                'O', 
                1000, 
                'OTP', 
                1000,
                '',
                1000,
                'OT',
                () => {
                }
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              style={{ color: 'white', fontSize: '6rem', marginLeft: 50, fontWeight: 'bolder', marginTop: -5 }}
            />
            <div style={{position: 'fixed', bottom: 0, left:0}}> 
                <input
                  style={{width: '100vw', padding: 0, height: '12vh', borderRadius: 0, fontSize: 20, fontWeight:'bolder' ,borderWidth: 1, borderColor: 'blue'}}
                  value={phoneNumber}
                  placeholder='   Phone Number'
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  type="text"  required />
                <div style={{width: '100vw', height: '10vh', borderRadius: 0, fontSize: 20, backgroundColor: 'blue', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} onClick={() => {signInWPhoneNumber()}}>
                  <h6 style={{color: 'white', fontSize: "1.5rem"}}>Generate OTP</h6>
                </div>
            </div>
        </div>
      )}
      {screenState == "otp" && (
        <div>
          <motion.div
                    style={{width: "100vw", height: "10vh", backgroundColor: 'blue',position: 'fixed', bottom: 0, left: 0, zIndex:0}}
                    animate={{
                      scale: [1, 10, 20, 30, 40, 50],
                    }}
                    onAnimationComplete={handleAnimationComplete}
                    transition={{
                        duration: 1,
                        ease: "easeInOut",
                        times: [0, 0, 0, 0, 0,0,0,0,0,0],
                        repeat: 0,
                        repeatDelay: 1
                    }}>
          </motion.div>
          {showContent && (
          <div style={{zIndex: 3, position: 'fixed', height: "100vh", width: "100vw", justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
            <h1 style={{color: 'white', fontSize: '2rem', marginLeft: 30, marginTop: '5vh', position: 'fixed', top: 0, left: 0}}>
              Enter Your<br/>
              <TypeAnimation
              sequence={[
                'OTP',
                1000, 
                'O', 
                1000, 
                'OTP', 
                1000,
                '',
                1000,
                'OT',
                () => {
                }
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              style={{ color: 'white', fontSize: '5rem', marginLeft: 5, fontWeight: 'bolder', marginTop: -5 }}
            />
            </h1>
              <OtpInput
                  value={otp}
                  onChange={setOtp}
                  inputStyle={{
                      width: '100%',
                      height: '100%',
                      fontSize: '2rem',
                      borderRadius: '5px',
                      border: '1px solid #000',
                      textAlign: 'center'}}
                  numInputs={6}
                  renderSeparator={<span style={{width: 5}}/>}
                  renderInput={(props) => <input {...props} />}
                />
                <motion.div  whileTap={{scale: 0.8}} style={{width: '90vw', height: '10vh', borderRadius: 10, margin: 20, fontSize: 20, backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} onClick={() => {otpSubmit()}}>
                  <h6 style={{color: 'blue', fontSize: "1.5rem"}}>Submit</h6>
                </motion.div>
                {/* <p style={{position: 'fixed', bottom: -5, left: '36vw', zIndex: 10, fontWeight: 'bold'}}>Crafted By Morbium</p> */}
                <Wave fill='white'
                        paused={false}
                        style={{position: 'fixed', bottom: -5, left: 0}}
                        options={{
                          height: 40,
                          amplitude: 40,
                          speed: 0.30,
                          points: 6
                        }}
                  />
          </div>
          )}

        </div>
        )}
        <div id="recaptcha-container"></div>
    </div>
  )
}
