import React,{useEffect,useState, useRef} from 'react'

import styles from '../styles/dashboard.module.css'
//db
import { auth, db, logout, storage } from "../firebase";
import { query, collection, where, onSnapshot, addDoc, orderBy, setDoc, doc, updateDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import { motion, useScroll } from "framer-motion"

//router
import { useNavigate } from "react-router-dom";

import toast, { Toaster } from 'react-hot-toast';

export default function Dashboard() {
    const [user, loading, error] = useAuthState(auth);
    const [profile, setProfile] = useState("");
    const [datafromdb, setDataFromDb] = useState([]); 
    const [allData, setallData] = useState([]);
    
    const { scrollYProgress } = useScroll();

    const [x, setX] = useState("0vw");
    const [y, setY] = useState(0);

    const [nameInput, setNameInput] = useState("")
    
    const [currentMenu, setcurrentMenu] = useState("outstanding")

    const [loadingState, setLoadingState] = useState(true) 
    const [imgFullScreen, setimgFullScreen] = useState(false) 
    const [imgSelected, setimgSelected] = useState("")
    const fileInput = useRef(null)
    const selectRef = useRef();

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);

    const bgColor = `rgba(0, 0, 0, .9)`

    //init navigator
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
        navigate("/");
        }
        fetchUser()
        fetchUpload()
      }, [user, loading]);

      //set progress
      let progress = 0;
      function inter(endValue){
        const interval = setInterval(() => {
            if (progress < endValue) {
            progress++;
            setProgresspercent(progress)
            console.log(progress);
            } else {
            clearInterval(interval);
            }
        }, 100);
        }
      
        const handleSubmit = (e) => {
            console.log("inside")
            const file = e

            if (!file) return;

            const storageRef = ref(storage, `${Math.floor(Math.random() * 1000000)}${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed",
            (snapshot) => {
                // setProgresspercent(Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
                inter(Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100))
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setImgUrl(downloadURL)
                });
            }
            );
        }

        async function setNewName(){
            if(nameInput != ""){
                await updateDoc(doc(db, "Users",`${user.phoneNumber}`), {
                    name: nameInput,
                }).then((e) => {console.log("name added")})
            }
        }

        async function addToArchive(documentids){
            await updateDoc(doc(db, "data",documentids), {
                collected: "true",
            }).then((e) => {console.log("collected")})
        }

        async function saveToDb(){
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            const formattedDate = `${year}${month}${day}`;
            const currentDate = new Date();
            const dateforDid = currentDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(/[/,: ]/g, '');
            try{
                setLoadingState(true)
                await setDoc(doc(db, "data",`${user.uid}${dateforDid}`), {
                    uid: user.uid,
                    phoneNumber: user.phoneNumber,
                    invoice: imgUrl,
                    category: selectRef.current.value,
                    collected: 'false',
                    date: formattedDate,
                    name: profile?.name,
                    time: currentDate.toLocaleString('en-US', {  hour: '2-digit', minute: '2-digit', second: '2-digit' }),
                    docID: `${user.uid}${dateforDid}`
                }).then((e) => {setImgUrl(null); setProgresspercent(0); setLoadingState(false)})
            } catch (err) {
                console.error(err);
                setLoadingState(false)
                alert(err.message);
            }
        }
        async function fetchUpload(){
            if(user?.uid != undefined){
                try {
                    const q = query(collection(db, "data"), where("uid", "==", user?.uid), orderBy("date", "desc"));
                    const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const dataArray = [];
                    querySnapshot.forEach((doc) => {
                            const data = doc.data();
                            data.id = doc.id;
                            dataArray.push(data);
                    });
                    setDataFromDb(dataArray)
                    });
                } catch (err) {
                    console.error(err);
                    alert("An error occured while fetching data");
                }
            }
          }
        async function fetchAllDataNotCollected(){
            if(user?.uid != undefined){
                try {
                    const q = query(collection(db, "data"), where("collected", "==", "false"), orderBy("date", "desc"));
                    const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const dataArray = [];
                    querySnapshot.forEach((doc) => {
                            const data = doc.data();
                            data.id = doc.id;
                            dataArray.push(data);
                    });
                    setallData(dataArray)
                    });
                } catch (err) {
                    console.error(err);
                    alert("An error occured while fetching data");
                }
            }
        }
        async function fetchAllDataCollected(){
            if(user?.uid != undefined){
                try {
                    const q = query(collection(db, "data"), where("collected", "==", "true"), orderBy("date", "desc"));
                    const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const dataArray = [];
                    querySnapshot.forEach((doc) => {
                            const data = doc.data();
                            data.id = doc.id;
                            dataArray.push(data);
                    });
                    setallData(dataArray)
                    setLoadingState(false)
                    });
                } catch (err) {
                    console.error(err);
                    setLoadingState(false)
                    alert("An error occured while fetching data");
                }
            }
        }

      async function fetchUser(){
        if(user?.uid != undefined){
        try {
            const q = query(collection(db, "Users"), where("uid", "==", user?.uid));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
              querySnapshot.forEach((doc,index,array) => {
                  console.log(doc.data());
                  if(doc.data()?.admin){
                    fetchAllDataNotCollected()
                  }
                  setLoadingState(false)
                  setProfile(doc.data())
              });
            });
          } catch (err) {
            console.error(err);
            alert("An error occured while fetching data");
          }
        }
      }
     
  return (
    <div>
        <Toaster
            position="bottom-center"
            reverseOrder={false}
            />
        {loadingState && (
            <div style={{height: "100vh", width: "100vw", backgroundColor: 'white', position: 'fixed', zIndex: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {/* <h1>Loading</h1> */}
                <motion.div
                    style={{width: 200, height: 200, backgroundColor: 'black'}}
                    animate={{
                        scale: [5, 1, 1, 1, 1, 5],
                        rotate: [0, 0, 180, 180,180, 0],
                        borderRadius: ["0%", "0%", "50%", "50%", "50%", "0%"]
                    }}
                    transition={{
                        duration: 2,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        repeat: Infinity,
                        repeatDelay: 1
                    }}
                    />
            </div>
        )}
        {profile?.admin ? (
            <div>
                <div style={{display: 'flex', flexDirection: 'row', width: "100vw", zIndex: 8, marginBottom:'5vh'}}>
                    <motion.div animate={{ x, y }} transition={{ type: "spring" }} className={styles.box}></motion.div>
                    <div onClick={() => {setcurrentMenu('outstanding'); fetchAllDataNotCollected(); setX('00vw')}} whileTap={{ scale: 1.1 }} style={{width: "50vw", justifyContent: 'center', display: 'flex',fontSize: "1.3rem", color: currentMenu == "outstanding" && ('white'), zIndex: 5}}>
                        <h3>Outstanding</h3>
                    </div>
                    <div onClick={() => {setcurrentMenu('collected'); fetchAllDataCollected(); setX('50vw')}}  style={{width: "50vw", justifyContent: 'center', display: 'flex', fontSize: "1.3rem", color: currentMenu == "collected" && ('white'),  zIndex: 5}}>
                        <h3>Collected</h3>
                    </div>
                </div>
                <div style={{width: "100vw", display: 'flex'}}>
                <motion.div whileTap={{scale: 0.8}} style={{display: progresspercent == 100 ? ("none"):("flex") }} onClick={() => {fileInput.current.click()}} className={styles.uploadContainer}>
                    <div style={{zIndex: 1, backgroundColor: '#e29578', height: '100%', width: `${progresspercent}%`, position: 'absolute', borderRadius: 'inherit'}} />
                    <div  style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: 2}}>
                        <h1 style={{fontSize: "1.5rem"}}>Upload <br/> <span style={{fontSize:"2.5rem", color:"#3e1f47"}}>Invoice</span></h1>
                        <img style={{marginLeft: "7vw", height: "11vh", width: 'auto'}} src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.onlinewebfonts.com%2Fsvg%2Fimg_503303.png&f=1&nofb=1&ipt=f4ea3da167afc0d48a83c195b334d9bc613bed8db4d6ff01d85599b9a92b1e5e&ipo=images' />
                        <input ref={fileInput} className={styles.uploadInput} accept=".png,.jpg,.jpeg" onChange={(event) => {handleSubmit(event.target.files[0]); toast('Please wait while uploading',{icon: '😎',style: {borderRadius: '10px',background: '#333',color: '#fff'},})} } style={{display:"none"}} type='file' />
                    </div>
                </motion.div> 
                </div>
                {imgUrl != "" && progresspercent == 100 && (
                    <div style={{width: "100vw", display: 'flex'}}>
                        <div className={styles.selectContainer}>
                            <div>
                                <h1 style={{ fontSize: '1.5rem'}}>Select <br/> Category</h1>
                                <select className={styles.neumorphicselect}  name="category" id="category" ref={selectRef} >
                                    <option value="transport">Transport</option>
                                    <option value="invoice">Invoice</option>
                                    <option value="expense">Expense</option>
                                    <option value="bills">Bills</option>
                                </select>
                            </div>
                            <div onClick={() => {saveToDb()}} style={{height: 50, width: 50, backgroundColor: 'red', borderRadius: 25, position: "absolute", right: 20, bottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                                <img style={{height: '3vh'}} src={require('../assets/icons/upload.png')} />
                            </div>
                        </div>
                    </div>
                    ) }
                {/* <input
                    className={styles.searchInput}
                    value={nameInput}
                    placeholder='Name'
                    onChange={(e) => setNameInput(e.target.value)}
                    type="text"  required /> */}
                {allData.map((e) => {return(
                    <div className={styles.items} style={{width: "90vw",overflow: 'visible', display: 'flex', flexDirection: 'row',overflowY:'scroll', alignItems: 'center', margin: 10, borderRadius: 20, padding: 10, position: 'relative' }}>
                        <img onClick={() => {setimgSelected(e.invoice); setimgFullScreen(true)}} style={{height: '25vh',objectFit:'contain',width: "30vw", margin: 10}} src={e.invoice} />
                        {/* <h4>Name: {e.name}<br/>phoneNumber: {e.phoneNumber}<br/>Category: {e.category}<br/>Collected: {e.collected}<br/>Date: {e.date.slice(0,4)+"/"+e.date.slice(4,6)+"/"+e.date.slice(6)}<br/>Time: {e?.time} </h4> */}
                        <div style={{height: '25vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <h5 style={{margin: 5}}>Name: {e.name}</h5>
                            <h5 style={{margin: 5}}>Category: {e.category}</h5>
                            <h5 style={{margin: 5}}>Date: {e.date.slice(0,4)+"/"+e.date.slice(4,6)+"/"+e.date.slice(6)}</h5>
                            <h5 style={{margin: 5}}>Time: {e.time}</h5>
                        </div>
                        {e.collected == "false" && (
                            <motion.div whileTap={{
                                scale: 0.8,
                                rotate: -360
                              }} onClick={() => {addToArchive(e.docID)}} style={{height: 60, width: 60, position: 'absolute', backgroundColor: 'palegoldenrod', borderRadius: 50, right: 10, bottom: 10, zIndex: 6, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img style={{height: 30}} src={require('../assets/icons/archive.png')} />
                            </motion.div>
                        )}
                    </div>
                )})}
                {imgFullScreen && (
                    <div style={{height: '100vh', width: "100vw", zIndex:8, position: "fixed", display: 'flex', flexDirection: 'column',top:0,justifyContent: 'center', alignItems: 'center', backgroundColor: bgColor}}>
                        <img onClick={() => {setimgFullScreen(false)}} style={{position: 'fixed', top: 10, right: 10, zIndex: 9}} src={require('../assets/icons/close.png')} />
                        <img onClick={() => {console.log("imageC")}} style={{height: "auto", width:"80vw"}} src={imgSelected} />
                    </div>
                )}
            </div>
        ):(
            <div className={styles.main}>
                 <motion.div
                    className={styles.progressbar}
                    style={{ scaleX: scrollYProgress }}
                />
                <motion.div whileTap={{scale: 0.8}} style={{display: progresspercent == 100 ? ("none"):("flex") }} onClick={() => {fileInput.current.click()}} className={styles.uploadContainer}>
                    <div style={{zIndex: 1, backgroundColor: '#e29578', height: '100%', width: `${progresspercent}%`, position: 'absolute', borderRadius: 'inherit'}} />
                    <div  style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: 2}}>
                        <h1 style={{fontSize: "1.5rem"}}>Upload <br/> <span style={{fontSize:"2.5rem", color:"#3e1f47"}}>Invoice</span></h1>
                        <img style={{marginLeft: "7vw", height: "11vh", width: 'auto'}} src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.onlinewebfonts.com%2Fsvg%2Fimg_503303.png&f=1&nofb=1&ipt=f4ea3da167afc0d48a83c195b334d9bc613bed8db4d6ff01d85599b9a92b1e5e&ipo=images' />
                        <input ref={fileInput} className={styles.uploadInput} accept=".png,.jpg,.jpeg" onChange={(event) => {handleSubmit(event.target.files[0]); toast('Please wait while uploading',{icon: '😎',style: {borderRadius: '10px',background: '#333',color: '#fff'},})} } style={{display:"none"}} type='file' />
                    </div>
                </motion.div> 
                {profile?.name == null && (
                    <div style={{height: "100vh", width: "100vw", backgroundColor: bgColor, position: 'fixed', zIndex:  9, position: 'fixed'}}>
                        <div style={{height: '30vh', width: "100vw", backgroundColor: 'white', position: "absolute", bottom: 0, borderTopLeftRadius: 40, borderTopRightRadius: 40}}>
                            <h1 style={{margin: 20, fontSize: "1.5rem"}}>Register<br/> <span style={{fontSize: "2rem", color: "orangered"}}>Your Name </span></h1>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <input
                                    className={styles.nameInput}
                                    value={nameInput}
                                    placeholder='Name'
                                    onChange={(e) => setNameInput(e.target.value)}
                                    type="text"  required />
                                <div onClick={() => {setNewName()}} style={{height: 70, width: 70, borderRadius: 35, backgroundColor: "palevioletred", marginLeft: 15, marginTop: '-1.5vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <img style={{height: 40, marginLeft: "-3vw"}} src={require('../assets/icons/plane.png')}></img>
                                </div>
                            </div>
                        </div>
                    </div>   
                )}
                    {imgUrl != "" && progresspercent == 100 && (
                        <div className={styles.selectContainer}>
                            <div>
                                <h1 style={{ fontSize: '1.5rem'}}>Select <br/> Category</h1>
                                <select className={styles.neumorphicselect}  name="category" id="category" ref={selectRef} >
                                    <option value="transport">Transport</option>
                                    <option value="invoice">Invoice</option>
                                    <option value="expense">Expense</option>
                                    <option value="bills">Bills</option>
                                </select>
                            </div>
                            <div onClick={() => {saveToDb()}} style={{height: 50, width: 50, backgroundColor: 'red', borderRadius: 25, position: "absolute", right: 20, bottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                                <img style={{height: '3vh'}} src={require('../assets/icons/upload.png')} />
                            </div>
                        </div>
                    ) }
                {/* <h1>{user?.phoneNumber}</h1> */}
                {/* <h1>{JSON.stringify(datafromdb.length)}</h1> */}
                <h1 style={{fontSize: "2rem", width: "80%", zIndex: 3}}>Your<br/><span style={{fontSize: "2.5rem", color: 'red'}}>Uploads</span></h1>
                <div>
                    {datafromdb.map((e) => {return(
                        <div className={styles.items} style={{width: "80vw", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',overflowY:'scroll', margin: 10, borderRadius: 20, padding: 10}}>
                            <img onClick={() => {setimgSelected(e.invoice); setimgFullScreen(true)}} style={{height: '10vh', margin: 10}} src={e.invoice} />
                            <h4>Category: {e.category}<br/>Collected: {e.collected}<br/>Date: {e.date.slice(0,4)+"/"+e.date.slice(4,6)+"/"+e.date.slice(6)} </h4>
                        </div>
                    )})
                    }
                    <div style={{height: 10}} />
                </div>
                <div onClick={() => {logout()}} style={{height: 60, width: 60, borderRadius: 30, backgroundColor: 'blueviolet',opacity: 0.9, position: 'fixed', bottom: 20, right: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{height: 25, marginLeft: -5}} src={require('../assets/icons/logout.png')} />
                </div>
                {imgFullScreen && (
                    <div style={{height: '100vh', width: "100vw", zIndex:8, position: "fixed", display: 'flex', flexDirection: 'column',justifyContent: 'center', alignItems: 'center', backgroundColor: bgColor}}>
                        <img onClick={() => {setimgFullScreen(false)}} style={{position: 'fixed', top: 10, right: 10, zIndex: 9}} src={require('../assets/icons/close.png')} />
                        <img onClick={() => {console.log("imageC")}} style={{height: "auto", width:"80vw"}} src={imgSelected} />
                    </div>
                )}
                
            </div>
        )}
    </div>
  )
}
